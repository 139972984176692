import { getCookie, getAgent } from './tools'

let bufferMouse = ""
let mouseMovement = []

let bufferScroll = 0
let stepScroll = []

const trackMouse = (currentMousePath) => {
    if(currentMousePath != bufferMouse && currentMousePath.id != ""){
        mouseMovement.push({
            element : currentMousePath.id,
            timestamp : new Date().getTime()
        })
        bufferMouse = currentMousePath
    }
}

const trackScroll = () => {
    let html = document.documentElement
    let body = document.body
    let percent = ( html['scrollTop'] || body['scrollTop'] ) / ( (html['scrollHeight']||body['scrollHeight'] ) - html.clientHeight) * 100;

    if(percent - bufferScroll >= 10 || percent - bufferScroll < -10){
        stepScroll.push({
            step: Math.floor(percent / 10) * 10,
            timestamp: new Date().getTime()
        })
        bufferScroll = Math.floor(percent / 10) * 10;
    }
}

const save = (tracking, path) => {
    let url  = path + '/Functions/save-scroll-data.php'

    let httpRequest = new XMLHttpRequest();
    httpRequest.open('POST', url, true);
    httpRequest.setRequestHeader('Content-Type', 'application/json');
    httpRequest.send(JSON.stringify(tracking));

    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState == XMLHttpRequest.DONE) {
            if (httpRequest.status == 200) {
                return
            }
        }
    }
}

const replaceAll = (find, replace, string) => {
    let re = new RegExp(find, 'g');
    string = string.replace(re, replace);
    return string
}

const initData = () => {
    let campaigns = decodeURI(getCookie(cookiesPrefix + '_campaign'))
    campaigns = replaceAll('%3A', ':', campaigns)
    campaigns = replaceAll('%2C', ',', campaigns)
    campaigns = JSON.parse(campaigns)

    let campaignsInfo = []
    for( let campaign in campaigns){
        if(cookiesCurrentCampaigns.includes(campaign)){
            campaignsInfo.push({
                id: campaign,
                visit: campaigns[campaign]
            })
        }        
    }

    let tracking = {
        cookieID: getCookie(cookiesPrefix),
        url : window.location.href,
        campagne: campaignsInfo,
        agent: getAgent(),
        timestamp : {
            begin : new Date().getTime()
        }
    }

    let utm = []
    let parseUrl = location.search.split('&')
    parseUrl.forEach(utmString => {
        let utmOption = RegExp('([^?&#;]*)=([^?&#;]*)').exec(utmString)
        if(utmOption != null){
            utm.push({
                parameter: utmOption[1],
                content: utmOption[2]
            })
        }        
    });

    if(utm.length > 0) tracking.utm = utm

    return(tracking)
}

export default (path) => {

    const leave = (event) => {
        tracking.scroll = stepScroll
        tracking.clicks = clicks
        tracking.mouse = mouseMovement
        tracking.timestamp.end = new Date().getTime()
        tracking.eventClose = 'leave'
        save(tracking, path)
    }

    let tracking = initData()
    let clicks = []
    let currentMousePath = ""
    let tickMouse = false
    let tickScroll = false

    if(cookiesTracking.includes('scroll') || cookiesTracking.includes('move')){
        setInterval(function() {
            if(tickScroll) {
                tickScroll = false;
                trackScroll()
            }
            if(tickMouse) {
                tickMouse = false;
                trackMouse(currentMousePath)
            }
        }, 150);
    }    

    if(!tracking.agent.includes('Mobile') && cookiesTracking.includes('move')){
        document.addEventListener('mousemove', (ev) => {
            tickMouse = true;
            currentMousePath = ev.path[0];
        })
    }    

    if(cookiesTracking.includes('scroll')){
        stepScroll.push(
            {
                step: 0,
                timestamp: new Date().getTime()
            }
        )
        document.addEventListener('scroll', (ev) => {
            tickScroll = true;
        }, {
            passive: true
        })
    }

    document.addEventListener('click', (ev) => {
        if(ev.target.nodeName == 'BUTTON'){
            if(cookiesTracking.includes('click')){
                clicks.push({
                    element: ev.target.id,
                    timestamp: new Date().getTime()
                })
            }
            if(ev.target.type == 'submit' && ev.target.id != "cookiebox-submit"){
                window.removeEventListener("beforeunload", leave);
                tracking.scroll = stepScroll
                tracking.clicks = clicks
                tracking.mouse = mouseMovement
                tracking.timestamp.end = new Date().getTime()
                tracking.eventClose = 'submit'
                save(tracking, path)
            }
        }        
    })

    window.addEventListener("beforeunload", leave);
}